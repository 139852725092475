import React, { useEffect, useState } from 'react'
import { SiteNavigation } from '../../components/Navigation/SiteNavigation'
import './Blog.css'
import Footer from '../../components/Footer/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faCircleQuestion } from '@fortawesome/free-solid-svg-icons'
import { Form } from 'react-bootstrap'
import contactImage from '../../images/design/knowledgebase_header.svg'
import { Helmet } from 'react-helmet-async'
import apiService from '../../services/apiService'

const Blog = () => {

    const [blogItems, setBlogItens] = useState();

    const getBlogPosts = async () => {
        let items = await apiService.getBlogItems();

        setBlogItens(items)
    }


    function changeDate(dbDate) {
        let date = new Date(dbDate);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();
        let hour = date.getHours();
        let minutes = date.getMinutes()

        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }

        if (hour < 10) {
            hour = '0' + hour;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }

        return year + '-' + month + '-' + dt;
    }

    useEffect(() => {
        getBlogPosts()
    }, [])

    return (
        <div>
            <Helmet>
                <title>Centnodes | Blog</title>
                <meta
                    name="description"
                    content="Read our Blog for intresting articles and hosting related content."
                />;
            </Helmet>
            <SiteNavigation />
            <div className='bg-white mt-0 border-bottom pb-4'>
                <div className="container">
                    <div className='row mt-4'>
                        <div className='col-md-4'>
                            <div className='card'>
                                <div className="card-body">
                                    <h5 className='card-title'>Blog</h5>
                                    <p>Read our Blog for intresting articles and hosting related content, provided by our team.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8'>
                            {blogItems && blogItems.length > 0 ? blogItems.map((blogItem) => (
                                <div className='card'>
                                    <div class="row p-3 mt-3">
                                        <div class="col-12 col-md-3">
                                            <img className='img-fluid h-100' src={blogItem.image} alt={blogItem.title} title={blogItem.title} />
                                        </div>
                                        <div class="col-12 col-md-9">
                                            <div class="row p-3">
                                                <div class="col-8 col-md-9 col-lg-10 p-0">
                                                    <a className='card-title h3 m-0' href={'/blog/' + blogItem.id}>{blogItem.title}</a>
                                                </div>
                                                <div class="col-4 col-md-3 col-lg-2">
                                                    <div class="published-date label">
                                                        <p>{changeDate(blogItem.created_at)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="description">
                                                {blogItem.description}
                                            </p>
                                            <a href={'/blog/' + blogItem.id} class="btn btn-centnodes">
                                                Read more
                                            </a></div>
                                    </div>
                                </div>
                            )) : <div className="card"><p className="mt-4 text-center">No blog posts yet.</p></div>}
                        </div>

                    </div>
                </div>

            </div>
            <Footer />
            <div className="botomButtons d-none" id={'backToTopButton'}>
                <div className="row p-0 m-0">
                    <div className="col-2 backToTop justify-content-start">
                        <FontAwesomeIcon className="backToTopIcon" onClick={() => window.scrollTo(0, 0)} icon={faArrowUp} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Blog;
