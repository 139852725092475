import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './BackToHome.css'

export const BackToHome = () => {
    const toHome = () => {
        window.location.href = "/";
    }
    return (
        <div className="">
            <FontAwesomeIcon id="backToHome" icon={faArrowLeft} size={'lg'} onClick={toHome} />
        </div>
    )
}
