import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SiteNavigation } from "../../components/Navigation/SiteNavigation";
import serverHeaderImage from "../../images/design/header_home.svg";
import './Home.css'
import Footer from "../../components/Footer/Footer";
import { faArrowUp, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import freedom_home from '../../images/design/freedom_home.svg'
import os_home from '../../images/design/os_home.svg'
import terms_home from '../../images/design/terms_home.svg'
import world_home from '../../images/design/world_home.svg'
import { Helmet } from "react-helmet-async";

export const Home = () => {
    const [selectedPlan, setSelectedPlan] = useState('vps');

    useEffect(() => {


    }, [])
    return (
        <>
            <Helmet>
                <title>Centnodes | Home</title>
                <meta
                    name="description"
                    content="Whether you’re just getting started or you’re a cloud native, we’ve got the tools you need to easily manage your server."
                />;
            </Helmet>
            <SiteNavigation />
            <div className="border-bottom py-4 bg-test">
                <div className="container  ">
                    <div className="row align-items-center mt-5 ">
                        <div className="col-md-5 d-flex flex-column">
                            <span className="h5">Centnodes</span>
                            <span className="h2 mt-2">Find your perfect server and serve it to the world.</span>
                            <p className="mt-2">Whether you’re just getting started or you’re a cloud native, we’ve got the tools you need to easily manage your server.</p>
                            <div className="btn-group">
                                <a href="/services/servers" className="btn btn-primary rounded m-1">See packages</a>
                                <a href="/help" className="btn btn-outline-primary rounded m-1">Contact Us</a>
                            </div>
                        </div>
                        <div className="col-md-7 d-flex justify-content-center align-items-center mt-5 mt-xl-0">
                            <div className="content-img"><img src={serverHeaderImage} alt="server" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white pb-4">
                <div className="container ">
                    <div className="row text-center">
                        <h1 className="h2 mt-5">Unified Solutions for a Digital World</h1>
                        <p className="mt-3">Below we list our best services</p>
                    </div>
                    <div className="features row row-hover-bg">
                        <div className="col border rounded p-4 d-flex justify-content-center align-items-center flex-column m-2" data-aos="fade-down-right">
                            <img alt="world icon" className="img-fluid" src={world_home} />
                            <span className="h5 mt-3 font-bold">High-Performance Gaming Servers</span>
                            <p>Our gaming servers are optimized for low latency and high performance, ensuring an immersive gaming experience.</p>
                            <a href="/services/gaming/minecraft" className="btn btn-outline-primary">See Packages</a>
                        </div>
                        <div className="col border rounded p-4 d-flex justify-content-center align-items-center flex-column m-2" data-aos="fade-down-left">
                            <img alt="terms icon" className="img-fluid" src={terms_home} />
                            <span className="h5 mt-3 font-bold">Flexible Cloud VPS Solutions</span>
                            <p>Our Cloud hosting provides the flexibility and control you need for your applications and websites.</p>
                            <a href="/services/servers" className="btn btn-outline-primary">See Packages</a>
                        </div>
                        <div className="col border rounded p-4 d-flex justify-content-center align-items-center flex-column m-2" data-aos="fade-up">
                            <img alt="os icon" className="img-fluid" src={os_home} />
                            <span className="h5 mt-3 font-bold">Reliable Web Hosting</span>
                            <p>Host your website with confidence using our reliable and secure web hosting services.</p>
                            <a href="/services/shared-hosting" className="btn btn-outline-primary">See Packages</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row mt-5 text-center">
                    <h1 className="h2">Choose the Plan that Suits You, from Starter to Expert</h1>
                </div>
                <div className="row m-0 g-2 mt-5">
                    <div className="hostingPlanSwitch mt-3 mb-2 text-center">
                        <h4 className="h4">
                            <Form.Check
                                inline
                                label="Gaming"
                                name="planType"
                                type="radio"
                                id={`planType-gaming`}
                                checked={selectedPlan === 'gaming'}
                                onChange={() => setSelectedPlan('gaming')}
                            />
                            <Form.Check
                                inline
                                label="Cloud VPS"
                                name="planType"
                                type="radio"
                                id={`planType-vps`}
                                checked={selectedPlan === 'vps'}
                                onChange={() => setSelectedPlan('vps')}
                            />
                            <Form.Check
                                inline
                                label="Web Hosting"
                                name="planType"
                                type="radio"
                                id={`planType-webhosting`}
                                checked={selectedPlan === 'webhosting'}
                                onChange={() => setSelectedPlan('webhosting')}
                            />
                        </h4>
                    </div>

                    {selectedPlan === 'gaming' && (
                        <>
                            <div className="col-md-3 col-lg-3 col-6">
                                <div className="hostingPackage p-4 bg-white">
                                    <div className="hostingPackageTitle p-0 border">
                                        <h5>Gaming Plan I</h5>
                                    </div>
                                    <h6 className="border-top pt-2">Specifications</h6>
                                    <div className="planSpecifications mt-3">
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">2 GB</span> RAM</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">15 GB</span> SSD Storage</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">Unlimited</span> Bandwidth</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">1</span> Database</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">1</span> Free Subdomain</p>
                                    </div>
                                    <h5 className="text-center">Free <span className="month">/mo</span></h5>
                                    <div className="mt-4 d-flex justify-content-center">
                                        <a href={'https://my.centnodes.com/login'} className="btn btn-centnodes">Order {'>'}</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-lg-3 col-6">
                                <div className="hostingPackage p-4 bg-white">
                                    <div className="hostingPackageTitle p-0 border">
                                        <h5>Gaming Plan II</h5>
                                    </div>
                                    <h6 className="border-top pt-2">Specifications</h6>
                                    <div className="planSpecifications mt-3">
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">4 GB</span> RAM</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">25 GB</span> SSD Storage</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">Unlimited</span> Bandwidth</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">2</span> Databases</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">2</span> Free Subdomains</p>
                                    </div>
                                    <h5 className="text-center">&euro;2,99 /mo</h5>
                                    <div className="mt-4 d-flex justify-content-center">
                                        <a href={'https://my.centnodes.com/login'} className="btn btn-centnodes">Order {'>'}</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-lg-3 col-6">
                                <div className="hostingPackage p-4 bg-white">
                                    <div className="hostingPackageTitle p-0 border">
                                        <h5>Gaming Plan III</h5>
                                    </div>
                                    <h6 className="border-top pt-2">Specifications</h6>
                                    <div className="planSpecifications mt-3">
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">8 GB</span> RAM</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">50 GB</span> SSD Storage</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">Unlimited</span> Bandwidth</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">3</span> Databases</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">3</span> Free Subdomains</p>
                                    </div>
                                    <h5 className="text-center">&euro;4,99 /mo</h5>
                                    <div className="mt-4 d-flex justify-content-center">
                                        <a href={'https://my.centnodes.com/login'} className="btn btn-centnodes">Order {'>'}</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-lg-3 col-6">
                                <div className="hostingPackage p-4 bg-white">
                                    <div className="hostingPackageTitle p-0 border">
                                        <h5>Gaming Plan IV</h5>
                                    </div>
                                    <h6 className="border-top pt-2">Specifications</h6>
                                    <div className="planSpecifications mt-3">
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">12 GB</span> RAM</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">65 GB</span> SSD Storage</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">Unlimited</span> Bandwidth</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">4</span> Databases</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">4</span> Free Subdomains</p>
                                    </div>
                                    <h5 className="text-center">&euro;14,99 /mo</h5>
                                    <div className="mt-4 d-flex justify-content-center">
                                        <a href={'https://my.centnodes.com/login'} className="btn btn-centnodes">Order {'>'}</a>
                                    </div>
                                </div>
                            </div>

                        </>
                    )}
                    {selectedPlan === 'vps' && (
                        <>
                            <div className="col-md-3 col-lg-3 col-6">
                                <div className="hostingPackage p-4 bg-white">
                                    <div className="hostingPackageTitle p-0 border">
                                        <h5>Cloud VPS I</h5>
                                    </div>
                                    <h6 className="border-top pt-2">Specifications</h6>
                                    <div className="planSpecifications mt-3">
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">1 Core</span> CPU</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">2 GB</span> RAM</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">20 GB</span> SSD Storage</p>
                                    </div>
                                    <h5 className="text-center">&euro;4,99 /mo</h5>
                                    <div className="mt-4 d-flex justify-content-center">
                                        <a href={'https://my.centnodes.com/login'} className="btn btn-centnodes">Order {'>'}</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-lg-3 col-6">
                                <div className="hostingPackage p-4 bg-white">
                                    <div className="hostingPackageTitle p-0 border">
                                        <h5>Cloud VPS II</h5>
                                    </div>
                                    <h6 className="border-top pt-2">Specifications</h6>
                                    <div className="planSpecifications mt-3">
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">2 Core</span> CPU</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">4 GB</span> RAM</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">40 GB</span> SSD Storage</p>
                                    </div>
                                    <h5 className="text-center">&euro;9,99 /mo</h5>
                                    <div className="mt-4 d-flex justify-content-center">
                                        <a href={'https://my.centnodes.com/login'} className="btn btn-centnodes">Order {'>'}</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-lg-3 col-6">
                                <div className="hostingPackage p-4 bg-white">
                                    <div className="hostingPackageTitle p-0 border">
                                        <h5>Cloud VPS III</h5>
                                    </div>
                                    <h6 className="border-top pt-2">Specifications</h6>
                                    <div className="planSpecifications mt-3">
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">4 Core</span> CPU</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">12 GB</span> RAM</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">80 GB</span> SSD Storage</p>
                                    </div>
                                    <h5 className="text-center">&euro;19,99 /mo</h5>
                                    <div className="mt-4 d-flex justify-content-center">
                                        <a href={'https://my.centnodes.com/login'} className="btn btn-centnodes">Order {'>'}</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-lg-3 col-6">
                                <div className="hostingPackage p-4 bg-white">
                                    <div className="hostingPackageTitle p-0 border">
                                        <h5>Cloud VPS IV</h5>
                                    </div>
                                    <h6 className="border-top pt-2">Specifications</h6>
                                    <div className="planSpecifications mt-3">
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">6 Core</span> CPU</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">24 GB</span> RAM</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">100 GB</span> SSD Storage</p>
                                    </div>
                                    <h5 className="text-center">&euro;49,99 /mo</h5>
                                    <div className="mt-4 d-flex justify-content-center">
                                        <a href={'https://my.centnodes.com/login'} className="btn btn-centnodes">Order {'>'}</a>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {selectedPlan === 'webhosting' && (
                        <>
                            <div className="col-md-3 col-lg-3 col-6">
                                <div className="hostingPackage p-4 bg-white">
                                    <div className="hostingPackageTitle p-0 border">
                                        <h5>Shared Hosting I</h5>
                                    </div>
                                    <h6 className="border-top pt-2">Specifications</h6>
                                    <div className="planSpecifications mt-3">
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">Linux</span> Webhosting</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">5 GB</span> SSD Storage</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">5</span> Email Accounts</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">1</span> Domain Hosted</p>
                                    </div>
                                    <h5 className="text-center">Free /mo</h5>
                                    <div className="mt-4 d-flex justify-content-center">
                                        <a href={'https://my.centnodes.com/login'} className="btn btn-centnodes">Order {'>'}</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-lg-3 col-6">
                                <div className="hostingPackage p-4 bg-white">
                                    <div className="hostingPackageTitle p-0 border">
                                        <h5>Shared Hosting II</h5>
                                    </div>
                                    <h6 className="border-top pt-2">Specifications</h6>
                                    <div className="planSpecifications mt-3">
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">Linux</span> Webhosting</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">20 GB</span> SSD Storage</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">10</span> Email Accounts</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">2</span> Domains Hosted</p>
                                    </div>
                                    <h5 className="text-center">&euro;4,99 /mo</h5>
                                    <div className="mt-4 d-flex justify-content-center">
                                        <a href={'https://my.centnodes.com/login'} className="btn btn-centnodes">Order {'>'}</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-lg-3 col-6">
                                <div className="hostingPackage p-4 bg-white">
                                    <div className="hostingPackageTitle p-0 border">
                                        <h5>Shared Hosting III</h5>
                                    </div>
                                    <h6 className="border-top pt-2">Specifications</h6>
                                    <div className="planSpecifications mt-3">
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">Linux</span> Webhosting</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">50 GB</span> SSD Storage</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">25</span> Email Accounts</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">5</span> Domains Hosted</p>
                                    </div>
                                    <h5 className="text-center">
                                        <h5 className="text-center">&euro; 9,99 /mo</h5>
                                    </h5>
                                    <div className="mt-4 d-flex justify-content-center">
                                        <a href={'https://my.centnodes.com/login'} className="btn btn-centnodes">Order {'>'}</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-lg-3 col-6">
                                <div className="hostingPackage p-4 bg-white">
                                    <div className="hostingPackageTitle p-0 border">
                                        <h5>Shared Hosting IV</h5>
                                    </div>
                                    <h6 className="border-top pt-2">Specifications</h6>
                                    <div className="planSpecifications mt-3">
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">Linux</span> Webhosting</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">75 GB</span> SSD Storage</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">Unlimited</span> Email Accounts</p>
                                        <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">Unlimited</span> Domains Hosted</p>
                                    </div>
                                    <h5 className="text-center">&euro;14,99 /mo</h5>
                                    <div className="mt-4 d-flex justify-content-center">
                                        <a href={'https://my.centnodes.com/login'} className="btn btn-centnodes">Order {'>'}</a>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}


                </div>
            </div>


            <section className="bg-light mt-5 pt-5 pb-5 border-top border-bottom">
                <h4 className="h2 lead-title">Why choose Centnodes?</h4>
                <p className="lead-text">Explore the benefits of choosing Centnodes for your hosting, gaming, and cloud solutions</p>
                <div className="row gy-4 box text-start justify-content-center align-items-center">

                    <div className="col-xl-4 col-lg-5">
                        <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-white aos-init" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <span className="h5 mt-2">24/7/365 Support</span>
                            <span>Our expert team is available around the clock to assist with hosting, game servers, or cloud services.</span>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-5">
                        <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-white aos-init" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <span className="h5 mt-2">Optimized Servers</span>
                            <span>Our servers are optimized for web hosting, gaming, and cloud computing, ensuring peak performance.</span>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-5">
                        <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-white aos-init" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <span className="h5 mt-2">Scalability</span>
                            <span>Easily upgrade your resources as your business or gaming community grows.</span>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-5">
                        <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-white aos-init" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <span className="h5 mt-2">Advanced Security</span>
                            <span>Protect your projects with our firewall and DDoS protection for uninterrupted services.</span>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-5">
                        <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-white aos-init" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <span className="h5 mt-2">Instant Deployment</span>
                            <span>All services, including VPS, game servers, and hosting plans, are activated immediately after purchase.</span>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-5">
                        <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-white aos-init" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <span className="h5 mt-2">Flexibility</span>
                            <span>Choose your preferred operating system, software, and configuration to suit your needs.</span>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-5">
                        <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-white aos-init" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <span className="h5 mt-2">Gaming Excellence</span>
                            <span>Enjoy lag-free gameplay and dedicated support for SA-MP, Minecraft, and more.</span>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-5">
                        <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-white aos-init" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <span className="h5 mt-2">Backups</span>
                            <span>Take Backups of your VPS to restore your projects to a previous state with ease.</span>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-5">
                        <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-white aos-init" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <span className="h5 mt-2">Cost-Effective Plans</span>
                            <span>Choose from a wide range of plans that fit your budget without compromising quality.</span>
                        </div>
                    </div>
                </div>
            </section >
            <section aria-label="Common questions" className="pt-5 border-bottom">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-md-6">
                            <div className="text-left me-5">
                                <span className="h2">Common Questions</span>
                                <p className="mt-1">To help you make an informed decision, we've compiled a list of our frequently asked questions related to the services listed on this page.</p>
                                <p>If you cannot find the answer to your question or would like more information on the matter, you can visit our complete knowledge base or contact us at any time, and we will be happy to assist you.</p>

                                {/* Additional Content Starts Here */}
                                <h4 className="mt-1 h4">Our Commitment to Excellence</h4>
                                <p>At Centnodes, we are dedicated to providing top-notch services that cater to your specific needs. Whether you're a gamer seeking the best performance, a business looking for reliable Cloud solutions, or an individual needing dependable web hosting, we've got you covered.</p>

                                <h4 className="mt-1 h4">Join Our Community</h4>
                                <p>Become part of a growing community that trusts Centnodes for their hosting needs. We value every customer and strive to build long-lasting relationships.</p>
                                <a href="https://discord.gg/9yNxB4BTFE" target="_blank" className="btn btn-primary mt-1 mb-1 me-1">Discord</a>


                                <h4 className="mt-1 h4">Tailored Solutions for Everyone</h4>
                                <ul className="">
                                    <li><strong>Gaming Servers:</strong> Experience low-latency gaming with our high-performance servers optimized for various popular games.</li>
                                    <li><strong>Cloud Hosting:</strong> Enjoy full control and flexibility with our scalable Virtual Private Servers.</li>
                                    <li><strong>Web Hosting:</strong> Get your website online with our secure and reliable hosting plans suitable for blogs, portfolios, or business sites.</li>
                                </ul>


                                <h4 className="mt-2 h4">Unparalleled Support</h4>
                                <p>Our support team is available 24/7/365 to assist you with any queries or issues. We pride ourselves on our quick response times and comprehensive assistance.</p>

                                <div className="btn-groups mt-3">
                                    <a href="/knowledgebase" className="btn btn-primary mt-1 mb-1 me-1">Knowledge Base</a>
                                    <a href="/help" className="btn btn-outline-primary mt-1 mb-1">Contact Us</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="text-left">
                                <span className="h3">Do you have any questions?</span>
                                <p className="mt-3">Find answers to some of the most common questions about our services below.</p>
                                <div className="accordion accordion-flush" id="accordionQuestion">
                                    <div className="accordion-item border mt-1 mb-1">
                                        <h2 className="accordion-header" id="flush-headingOne">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                How quickly can I get my server set up?
                                            </button>
                                        </h2>
                                        <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionQuestion">
                                            <div className="accordion-body">
                                                We offer instant provisioning for most of our services. Your gaming server, Cloud VPS, or web hosting account will be ready to use within minutes after your payment is confirmed.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item border mt-1 mb-1">
                                        <h2 className="accordion-header" id="flush-headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                Do you provide customer support?
                                            </button>
                                        </h2>
                                        <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionQuestion">
                                            <div className="accordion-body">
                                                Yes, our support team is available 24/7/365 to assist you with any questions or issues you may have regarding our services.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item border mt-1 mb-1">
                                        <h2 className="accordion-header" id="flush-headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                Can I upgrade or downgrade my plan later?
                                            </button>
                                        </h2>
                                        <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionQuestion">
                                            <div className="accordion-body">
                                                Absolutely! Our services are flexible, allowing you to upgrade or downgrade your plan at any time to suit your changing needs.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item border mt-1 mb-1">
                                        <h2 className="accordion-header" id="flush-headingFive">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                How do I manage my server or hosting account?
                                            </button>
                                        </h2>
                                        <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionQuestion">
                                            <div className="accordion-body">
                                                We provide an intuitive control panel for all our services. For web hosting, you'll get access to CWP Panel. For gaming we got our own custom Pterodactyl panel and your Cloud VPSs, you'll have a dedicated dashboard to manage your server settings via our controlpanel.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item border mt-1 mb-1">
                                        <h2 className="accordion-header" id="flush-headingSix">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                                What payment methods do you accept?
                                            </button>
                                        </h2>
                                        <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionQuestion">
                                            <div className="accordion-body">
                                                We accept a variety of payment methods, including major credit cards, PayPal, and bank transfers. All transactions are processed securely.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item border mt-1 mb-1">
                                        <h2 className="accordion-header" id="flush-headingSeven">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                                Is there any downtime during server maintenance?
                                            </button>
                                        </h2>
                                        <div id="flush-collapseSeven" className="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionQuestion">
                                            <div className="accordion-body">
                                                We strive to maintain 99.9% uptime across all our services. Any scheduled maintenance is performed during off-peak hours and is communicated in advance to minimize impact.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item border mt-1 mb-1">
                                        <h2 className="accordion-header" id="flush-headingEight">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                                How secure are your servers?
                                            </button>
                                        </h2>
                                        <div id="flush-collapseEight" className="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionQuestion">
                                            <div className="accordion-body">
                                                Security is our top priority. We implement advanced firewalls, regular security updates, and continuous monitoring to protect your data and services.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item border mt-1 mb-1">
                                        <h2 className="accordion-header" id="flush-headingNine">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                                Can I install custom software on my server?
                                            </button>
                                        </h2>
                                        <div id="flush-collapseNine" className="accordion-collapse collapse" aria-labelledby="flush-headingNine" data-bs-parent="#accordionQuestion">
                                            <div className="accordion-body">
                                                Yes, with our Cloud VPS and dedicated gaming servers, you have full root access, allowing you to install any software that complies with our acceptable use policy.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item border mt-1 mb-1">
                                        <h2 className="accordion-header" id="flush-headingTen">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                Do you offer data backups?
                                            </button>
                                        </h2>
                                        <div id="flush-collapseTen" className="accordion-collapse collapse" aria-labelledby="flush-headingTen" data-bs-parent="#accordionQuestion">
                                            <div className="accordion-body">
                                                Yes, we offer automated backup solutions for all our services. You can schedule regular backups and restore data as needed through your control panel.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <div className="container">
                <div className="row my-5 m-0 pageTextBreak border">
                    <div className="col-12 col-md-8 col-lg-8">
                        <div className='pageTextBreakBox'>
                            <h3 className="">Experience complete freedom with hosting from Centnodes</h3>
                            <h6>With Centnodes, you gain access to high-performance Gaming Servers, flexible Cloud hosting, and reliable Web Hosting—all designed to meet your unique needs. Whether you're a gamer seeking seamless gameplay, a business needing scalable virtual servers, or an individual looking to host a website, we have the perfect solution for you. Find out why Centnodes is the best provider for all your hosting needs.</h6>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-4 d-none d-md-block d-lg-block">
                        <img alt="freedom  icon" src={freedom_home} className="img-fluid imageTextBreak mt-4" />
                    </div>

                </div>
            </div>

            <Footer />
            <div className="botomButtons d-none" id={'backToTopButton'}>
                <div className="row p-0 m-0">
                    <div className="col-2 backToTop justify-content-start">
                        <FontAwesomeIcon className="backToTopIcon" onClick={() => window.scrollTo(0, 0)} icon={faArrowUp} />
                    </div>
                </div>
            </div>
        </>
    )
}
