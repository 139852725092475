import {
    faEnvelope,
    faTicket,
    faComment,
    faClock,
    faExclamationTriangle,
    faGamepad,
    faServer,
    faGlobe,
    faBook
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Footer from '../../components/Footer/Footer';
import { SiteNavigation } from '../../components/Navigation/SiteNavigation';
import './Help.css';
import contactImage from '../../images/design/support_header.svg';
import { Helmet } from 'react-helmet-async';

export const Help = () => {
    return (
        <div>
            <Helmet>
                <title>Centnodes | Support</title>
                <meta
                    name="description"
                    content="Our skilled and enthusiastic team is ready to assist you with any queries or issues. Contact us through email, live chat, or support tickets for quick and efficient support."
                />
            </Helmet>
            <SiteNavigation />

            <div className="border-bottom py-4">
                <div className="container ">
                    <div className="row align-items-center mt-5 ">
                        <div className="col-md-5 d-flex flex-column">
                            <span className="h5">Support</span>
                            <span className="h2 mt-2">Need help? We're here for you!</span>
                            <p className="mt-2">Our whole team of skilled and enthusiasts are ready to assist you, whether it's password resets, setting up your server, or optimizing your gaming experience. Use any method on this page to get in touch with us.
                            </p>
                            <div className="btn-group">
                                <a href="/knowledgebase" className="btn btn-primary rounded m-1">See Knowedgebase</a>
                                <a href="mailto:support@centnodes.com" className="btn btn-outline-primary rounded m-1">Email now</a>
                            </div>
                        </div>
                        <div className="col-md-7 d-flex justify-content-center align-items-center mt-5 mt-xl-0">
                            <div className="content-img"><img src={contactImage} alt="server" /></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="border-bottom bg-white py-5">
                <div className="container">
                    <h2 className="text-center mb-5">How Can We Help You?</h2>
                    <div className="row align-items-start">
                        <div className="col-md-6">
                            <h4>Email Support</h4>
                            <p>
                                Reach out to us via email for detailed assistance. Our team ensures that all queries are addressed promptly within 24 hours.
                            </p>
                            <FontAwesomeIcon icon={faEnvelope} color="#2f57a4" size="2xl" />
                            <p className="mt-2">
                                <strong>Email:</strong>{' '}
                                <a href="mailto:support@centnodes.com">support@centnodes.com</a>
                            </p>
                        </div>
                        {/* <div className="col-md-6">
                            <h4>Live Chat</h4>
                            <p>
                                Prefer real-time help? Connect with one of our support specialists via live chat for instant resolutions to your issues.
                            </p>
                            <FontAwesomeIcon icon={faComment} color="#2f57a4" size="2xl" />
                            <p className="mt-2">
                                <strong>Availability:</strong> 24/7 Live Support
                            </p>
                        </div> */}
                        <div className="col-md-6">
                            <h4>Emergency Support</h4>
                            <p>
                                For urgent issues, reach out to us via our dedicated emergency email or join our support Discord channel.
                            </p>
                            <FontAwesomeIcon icon={faExclamationTriangle} color="#2f57a4" size="2xl" />
                            <p className="mt-2">
                                <strong>Email:</strong>{' '}
                                <a href="mailto:emergency@centnodes.com">emergency@centnodes.com</a>
                            </p>
                            <p>
                                <strong>Discord:</strong>{' '}
                                <a href="https://discord.gg/jSyNCQ2HWK" target="_blank" rel="noopener noreferrer">
                                    Join Our Discord
                                </a>
                            </p>
                        </div>
                    </div>
                    <hr className="my-5" />
                    <div className="row align-items-start">
                        <div className="col-md-6">
                            <h4>Submit a Ticket</h4>
                            <p>
                                Need more detailed help? Submit a support ticket through our system and track its progress in real-time.
                            </p>
                            <FontAwesomeIcon icon={faTicket} color="#2f57a4" size="2xl" />
                            <p className="mt-2">
                                <a href="https://my.centnodes.com/tickets" target="_blank" rel="noopener noreferrer" className="btn btn-primary">
                                    Submit a Ticket
                                </a>
                            </p>
                        </div>
                        <div className="col-md-6">
                            <h4>Check our knowledgebase</h4>
                            <p>
                                Need more detailed help? See if we already have your answer at our knowledgebase. We might have your answer already.
                            </p>
                            <FontAwesomeIcon icon={faBook} color="#2f57a4" size="2xl" />
                            <p className="mt-2">
                                <a href="https://centnodes.com/knowledgebase" target="_blank" rel="noopener noreferrer" className="btn btn-primary">
                                    knowledgebase
                                </a>
                            </p>
                        </div>

                    </div>
                </div>
            </div>

            <div className="py-5">
                <div className="container">
                    <h2 className="text-center mb-4">Service-Specific Support</h2>
                    <div className="row">
                        <div className="col-md-4">
                            <h5>Web Hosting</h5>
                            <p>
                                Get help with website migrations, DNS configurations, email hosting, and CMS setups like WordPress or Joomla.
                            </p>
                            <FontAwesomeIcon icon={faGlobe} color="#2f57a4" size="2xl" />
                        </div>
                        <div className="col-md-4">
                            <h5>VPS Assistance</h5>
                            <p>
                                Whether it's server provisioning, scaling, or security configurations, our team is here to guide you through it all.
                            </p>
                            <FontAwesomeIcon icon={faServer} color="#2f57a4" size="2xl" />
                        </div>
                        <div className="col-md-4">
                            <h5>Gaming Servers</h5>
                            <p>
                                From configuring mods to optimizing latency, we provide solutions tailored for gaming enthusiasts and server admins.
                            </p>
                            <FontAwesomeIcon icon={faGamepad} color="#2f57a4" size="2xl" />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};
