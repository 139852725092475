import {
    faBoltLightning,
    faLock,
    faToolbox,
    faShieldAlt,
    faChartLine,
    faServer
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../../components/Footer/Footer';
import { SiteNavigation } from '../../components/Navigation/SiteNavigation';
import './ControlFeature.css';
import controlPanelDesktop from '../../images/controlpanel_desktop.png';
import controlPanelPhone from '../../images/controlpanel_phone.png';
import serverHeaderImage from '../../images/design/server_controlpanel.svg';
import { Helmet } from 'react-helmet-async';

export const ControlFeature = () => {
    return (
        <div>
            <Helmet>
                <title>Centnodes | Our custom control panel</title>
                <meta
                    name="description"
                    content="Manage your VPS with ease using our intuitive and powerful control panel."
                />
            </Helmet>
            <SiteNavigation />
            <div className="border-bottom py-4">
                <div className="container">
                    <div className="row align-items-center mt-5">
                        <div className="col-md-5 d-flex flex-column">
                            <span className="h5">Control Panel</span>
                            <h2 className="h2 mt-2">The Ultimate VPS Management Tool</h2>
                            <p className="mt-2">Save time and focus on scaling your infrastructure with our secure, fast, and user-friendly control panel.</p>
                            <div className="btn-group">
                                <a href="/services/servers" className="btn btn-primary rounded m-1">See Packages</a>
                            </div>
                        </div>
                        <div className="col-md-7 d-flex justify-content-center align-items-center mt-5 mt-xl-0">
                            <div className="content-img">
                                <img src={serverHeaderImage} alt="server" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="features-section bg-light py-5">
                <div className="container">
                    <h2 className="text-center">What Makes Our Control Panel Stand Out?</h2>
                    <p className="text-center mb-4">Discover why Centnodes is the best choice for managing your VPS infrastructure.</p>
                    <div className="row mt-4">
                        <div className="col-md-4">
                            <div className="bg-white p-4 border text-center rounded">
                                <FontAwesomeIcon icon={faLock} size="2xl"  />
                                <h4>Top-notch Security</h4>
                                <p>Protect your servers with advanced encryption, 2FA options, and industry-leading security protocols.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="bg-white p-4 border text-center rounded">
                                <FontAwesomeIcon icon={faBoltLightning} size="2xl"  />
                                <h4>Lightning-fast Deployment</h4>
                                <p>Deploy and manage servers in just a few clicks with our streamlined control panel.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="bg-white p-4 border text-center rounded">
                                <FontAwesomeIcon icon={faToolbox} size="2xl"  />
                                <h4>Comprehensive Tools</h4>
                                <p>From monitoring to backups, manage everything in one unified portal.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-4">
                            <div className="bg-white p-4 border text-center rounded">
                                <FontAwesomeIcon icon={faShieldAlt} size="2xl"  />
                                <h4>Integrated Firewall</h4>
                                <p>Safeguard your infrastructure with customizable and easy-to-configure firewalls.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="bg-white p-4 border text-center rounded">
                                <FontAwesomeIcon icon={faChartLine} size="2xl"  />
                                <h4>Performance Insights</h4>
                                <p>Real-time analytics and performance data to help you optimize your servers effectively.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="bg-white p-4 border text-center rounded">
                                <FontAwesomeIcon icon={faServer} size="2xl"  />
                                <h4>Global Reach</h4>
                                <p>Deploy servers in data centers across the world to meet your business needs.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="panel-showcase-section py-5 bg-white">
                <div className="container">
                    <h2 className="text-center mb-4">See Our Control Panel in Action</h2>
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                            <div className="bg-white p-4 border text-center rounded shadow">
                                <img src={controlPanelDesktop} alt="Control Panel Desktop" className="img-fluid mb-3 rounded" />
                                <h4>Desktop View</h4>
                                <p>Effortlessly manage your infrastructure with our intuitive desktop interface.</p>
                            </div>
                        </div>
                        <div className="col-md-6 text-center mt-4 mt-md-0">
                            <div className="bg-white p-4 border text-center rounded shadow">
                                <img src={controlPanelPhone} alt="Control Panel Mobile" className="img-fluid mb-3 rounded" />
                                <h4>Mobile View</h4>
                                <p>Stay in control of your servers on the go with our responsive mobile interface.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cta-section pageTextBreak text-white text-center py-5">
                <div className="container">
                    <h3>Ready to Get Started?</h3>
                    <p className="text-white">Deploy your first server today and experience the power of Centnodes.</p>
                    <a target="_blank" href="https://my.centnodes.com" className="btn-centnodes btn-lg mt-3">Explore Plans</a>
                </div>
            </div>
            <Footer />
        </div>
    );
};
