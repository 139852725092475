import React from 'react'
import { SiteNavigation } from '../../components/Navigation/SiteNavigation'
import './404.css'

import Footer from '../../components/Footer/Footer'
import { BackToHome } from '../../components/BackToHome/BackToHome'

const Error404 = () => {
    return (
        <div>
            <SiteNavigation />
            <div className="hero-secondary p-4">
                <div className="container mt-5">
                    <BackToHome />
                    <div className='text-center'>
                        <h4 className="h4  text-white">Error 404: Page not found.</h4>
                        <a href="/" className="btn btn-primary mt-5">Go Home</a>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Error404;
