import { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home/Home.js";
import { ServerServices } from "./pages/Services/ServerServices";
import { Help } from "./pages/Help/Help";
import Error404 from "./pages/404/404";
import { Privacy } from "./pages/Legal/Privacy";
import { GDPR } from "./pages/Legal/GDPR";
import { TOS } from "./pages/Legal/TOS";
import ScrolltoTop from "./hooks/scrolltoTop";
import { OSListFeature } from "./pages/Features/OSListFeature";
import { LocationFeature } from "./pages/Features/LocationFeature";
import { ControlFeature } from "./pages/Features/ControlFeature";
import KnowledgeBaseItem from "./pages/KnowledgeBase/KnowledgeBaseItem";
import KnowledgeBase from "./pages/KnowledgeBase/KnowledgeBase";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import AOS from "aos";
import "aos/dist/aos.css";
import { HostingServices } from "./pages/Services/HostingServices";
import { HelmetProvider } from "react-helmet-async";
import { MinecraftServers } from "./pages/Services/Gaming/Minecraft";
import { SampServers } from "./pages/Services/Gaming/Samp";
import apiService from "./services/apiService";
import Blog from "./pages/Blog/Blog";
import BlogItem from "./pages/Blog/BlogItem";
import { CookieBanner } from "./components/CookieBanner/CookieBanner.js";

function App() {
  const [user, setUser] = useState();

  const getCookie = (cName) => {

    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded.split("; ");
    var value;
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) value = val.substring(name.length);
    });

    return value;
  };

  const getUserInfo = async () => {
    let user = await apiService.getUser(localStorage.getItem('id'));

    setUser(user);
  }

  useEffect(() => {
    AOS.init();
    AOS.refresh();

    if (getCookie('CENTNODES-AUTH-SECRET') && !user) {
      getUserInfo()
    }
    try {
      let element = document.getElementById('backToTopButton');
      if (element) {
        window.addEventListener('scroll', function () {

          if (window.scrollY > 100) {
            element.classList.remove('d-none')
          }
          if (window.scrollY < 100) {
            element.classList.add('d-none')

          }
        });
      }
    } catch (err) {
      return
    }

  })

  return (
    <>
      <HelmetProvider>
        <ScrolltoTop />
        <CookieBanner />

        <Routes>

          <Route path="/*" element={<Error404 />} />
          <Route path="/" element={<Home />} />

        
          <Route path="/services/servers" element={<ServerServices />} />
          <Route path="/services/shared-hosting" element={<HostingServices />} />
          <Route path="/services/gaming/minecraft" element={<MinecraftServers />} />
          <Route path="/services/gaming/sa-mp" element={<SampServers />} />

          <Route path="/features/oslist" element={<OSListFeature />} />
          <Route path="/features/locations" element={<LocationFeature />} />
          <Route path="/features/controlpanel" element={<ControlFeature />} />

          <Route path="/help" element={<Help />} />

          <Route path="/privacy" element={<Privacy />} />
          <Route path="/gdpr" element={<GDPR />} />
          <Route path="/tos" element={<TOS />} />

          <Route path="/knowledgebase/read/:id" element={<KnowledgeBaseItem />} />
          <Route path="/knowledgebase" element={<KnowledgeBase />} />
          <Route path="/knowledgebase/:cat" element={<KnowledgeBase />} />

          <Route path="/blog/:slug" element={<BlogItem />} />
          <Route path="/blog" element={<Blog />} />

        </Routes>
      </HelmetProvider>
    </>
  );
}
export default App;
