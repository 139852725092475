import React, { useState, useEffect } from 'react';

export const CookieBanner = () => {
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const cookiesAccepted = document.cookie.includes('cookies=accepted');
        const cookiesRejected = document.cookie.includes('cookies=rejected');

        if (!cookiesAccepted && !cookiesRejected) {
            setShowBanner(true);
        }
    }, []);

    const acceptCookies = () => {
        document.cookie = `cookies=accepted; path=/; max-age=${60 * 60 * 24 * 365};`; // 1 year
        setShowBanner(false);
    };

    const rejectCookies = () => {
        document.cookie = `cookies=rejected; path=/; max-age=${60 * 60 * 24 * 365};`; // 1 year
        setShowBanner(false);
    };

    if (!showBanner) return null;

    return (
        <div className="cookies-info container-fluid" role="dialog" aria-labelledby="cookie-banner" aria-describedby="cookie-description">
            <div className="cookies-info-container row w-100 border p-3 bg-light">
                <div className="col-md-10 d-flex align-items-center">
                    <p id="cookie-description" className="m-0">
                        The website uses cookies to personalize your experience. By accessing centnodes.com, you agree to our <a href="/privacy" className="link">Privacy Policy</a>. We may collect information for statistical and marketing purposes.
                    </p>
                </div>
                <div className="col-md-2 d-flex justify-content-center align-items-center">
                    <button onClick={acceptCookies} className="btn btn-primary m-1 rounded" aria-label="Accept Cookies">Accept</button>
                    <button onClick={rejectCookies} className="btn btn-outline-primary m-1 rounded" aria-label="Reject Cookies">Reject</button>
                </div>
            </div>
        </div>
    );
};
