import { useEffect, useState } from 'react';
import Footer from '../../components/Footer/Footer';
import { SiteNavigation } from '../../components/Navigation/SiteNavigation';
import './OSListFeature.css';
import deployDesktopimage from '../../images/deployserver_desktop.png';
import deployMobileImage from '../../images/deployserver_phone.png';
import deployTabletimage from '../../images/deployserver_tablet.png';
import { Tab, Tabs } from 'react-bootstrap';
import serverHeaderImage from '../../images/design/server_os.svg';
import apiService from '../../services/apiService';
import { Helmet } from 'react-helmet-async';

export const OSListFeature = () => {
    const [OSList, setOSList] = useState();

    const getOSList = async () => {
        let oslist = await apiService.getOperationSystems();
        setOSList(oslist.data.os);
    };

    const updateOS = async (osId) => {
        let elements = document.getElementsByClassName('osSelectionItem');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].dataset.key === osId) {
                elements[i].classList.add('osOptionSelected');
            } else {
                elements[i].classList.remove('osOptionSelected');
            }
        }
    };

    useEffect(() => {
        getOSList();
    }, []);

    return (
        <div>
            <Helmet>
                <title>Centnodes | Operating Systems</title>
                <meta
                    name="description"
                    content="Spin up a new VPS with your preferred operating system in just seconds."
                />
            </Helmet>
            <SiteNavigation />
            <div className="border-bottom py-4">
                <div className="container ">
                    <div className="row align-items-center mt-5 ">
                        <div className="col-md-5 d-flex flex-column">
                            <span className="h5">Operating Systems</span>
                            <span className="h2 mt-2">From Windows to Ubuntu to Debian.</span>
                            <p className="mt-2">Spin up a new VPS with your preferred operating system in just seconds.</p>
                            <div className="btn-group">
                                <a href="/services/servers" className="btn btn-primary rounded m-1">See Packages</a>
                            </div>
                        </div>
                        <div className="col-md-7 d-flex justify-content-center align-items-center mt-5 mt-xl-0">
                            <div className="content-img"><img src={serverHeaderImage} alt="server" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-bottom py-4 bg-white">
                <div className="container">
                    <h3 className="text-center h3 mt-5">Flexible Operating System Choices</h3>
                    <h6 className="text-center">
                        Deploy servers with a wide variety of supported operating systems tailored to your needs.
                    </h6>
                    <div className="row g-2 mt-5 make-equal">
                        <div className="col-6 col-sm-4 col-md-3 d-flex justify-content-center">
                            <div className="d-flex flex-column bg-light rounded p-3 text-center align-items-center border h-100 w-100">
                                <div className="row text-center">
                                    <div className="col-md-6">
                                        <p>CentOS 7/8</p>
                                    </div>
                                    <div className="col-md-6">
                                        <img
                                            src={require('../../images/os/icon-centos.png')}
                                            className="img-fluid mx-auto"
                                            alt="CentOS"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 d-flex justify-content-center">
                            <div className="d-flex flex-column bg-light rounded p-3 text-center align-items-center border h-100 w-100">
                                <div className="row text-center">
                                    <div className="col-md-6">
                                        <p>Ubuntu 22.04/24.04</p>
                                    </div>
                                    <div className="col-md-6">
                                        <img
                                            src={require('../../images/os/icon-ubuntu.png')}
                                            className="img-fluid mx-auto"
                                            alt="Ubuntu"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 d-flex justify-content-center">
                            <div className="d-flex flex-column bg-light rounded p-3 text-center align-items-center border h-100 w-100">
                                <div className="row text-center">
                                    <div className="col-md-6">
                                        <p>Debian 11/12 (Bookworm)</p>
                                    </div>
                                    <div className="col-md-6">
                                        <img
                                            src={require('../../images/os/icon-debian.png')}
                                            className="img-fluid mx-auto"
                                            alt="Debian"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 d-flex justify-content-center">
                            <div className="d-flex flex-column bg-light rounded p-3 text-center align-items-center border h-100 w-100">
                                <div className="row text-center">
                                    <div className="col-md-6">
                                        <p>Windows Server 2022/2019/2016</p>
                                    </div>
                                    <div className="col-md-6">
                                        <img
                                            src={require('../../images/os/icon-windows.png')}
                                            className="img-fluid mx-auto"
                                            alt="Windows"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 d-flex justify-content-center">
                            <div className="d-flex flex-column bg-light rounded p-3 text-center align-items-center border h-100 w-100">
                                <div className="row text-center">
                                    <div className="col-md-6">
                                        <p>Fedora 36/37</p>
                                    </div>
                                    <div className="col-md-6">
                                        <img
                                            src={require('../../images/os/icon-fedora.png')}
                                            className="img-fluid mx-auto"
                                            alt="Fedora"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row my-5 m-0 g-2 justify-content-center">
                        <h3 className="text-center mt-5 h3">Start Your Adventure Here at Centnodes, Quick and Easy!</h3>
                        <h6 className="text-center">
                            Centnodes' intuitive interface makes it simple to deploy high-performance servers worldwide, right from your device.
                        </h6>
                        <div className="col-md-8 mt-3">
                            <Tabs
                                defaultActiveKey="Desktop"
                                id="deployServerTabs"
                                className="mb-3"
                                justify
                            >
                                <Tab eventKey="Desktop" title="Desktop">
                                    <img
                                        alt="Deploy via Desktop"
                                        src={deployDesktopimage}
                                        className="img-fluid mx-auto d-block"
                                    />
                                </Tab>
                                <Tab eventKey="Tablet" title="Tablet">
                                    <img
                                        alt="Deploy via Tablet"
                                        src={deployTabletimage}
                                        className="img-fluid mx-auto d-block"
                                    />
                                </Tab>
                                <Tab eventKey="Mobile" title="Mobile">
                                    <img
                                        alt="Deploy via Mobile"
                                        src={deployMobileImage}
                                        className="img-fluid mx-auto d-block"
                                    />
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
