import { useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import { SiteNavigation } from '../../components/Navigation/SiteNavigation';
import './LocationFeature.css';
import flag_nl from "../../images/flags/flag_nl.png"
import flag_de from "../../images/flags/flag_de.png"
import deployDesktopimage from '../../images/deployserver_desktop.png';
import deployMobileImage from '../../images/deployserver_phone.png';
import deployTabletimage from '../../images/deployserver_tablet.png';
import serverHeaderImage from '../../images/design/world_home.svg';
import { Tab, Tabs } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

export const LocationFeature = () => {

    const updateOS = async (osId) => {
        let elements = document.getElementsByClassName('osSelectionItem');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].dataset.key === osId) {
                elements[i].classList.add('osOptionSelected');
            } else {
                elements[i].classList.remove('osOptionSelected');
            }
        }
    };

    useEffect(() => { });

    return (
        <div>
            <Helmet>
                <title>Centnodes | Server Locations</title>
                <meta
                    name="description"
                    content="Choose from a variety of worldwide server locations for optimal performance and low latency."
                />
            </Helmet>
            <SiteNavigation />

            <div className="border-bottom py-4">
                <div className="container ">
                    <div className="row align-items-center mt-5 ">
                        <div className="col-md-5 d-flex flex-column">
                            <span className="h5">Locations</span>
                            <span className="h2 mt-2">Top-notch servers across the globe.</span>
                            <p className="mt-2">Launch a high-performance SSD VPS on our worldwide KVM-based platform. Enjoy exceptional speed and reliability, no matter where you are.
                            </p>
                            <div className="btn-group">
                                <a href="/services/servers" className="btn btn-primary rounded m-1">See Packages</a>
                            </div>
                        </div>
                        <div className="col-md-7 d-flex justify-content-center align-items-center mt-5 mt-xl-0">
                            <div className="content-img"><img src={serverHeaderImage} alt="server" /></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="border-bottom py-4 bg-light">
                <div className="container">
                    <h3 className="text-center h3 mt-5">Low latency, high performance</h3>
                    <p className="text-center">
                        Select a server location nearest to you for the best performance and low latency.
                    </p>
                    <div className="row g-2 mt-5">
                        {[{
                            key: 'ams', country: 'Netherlands', city: 'Amsterdam', flag: flag_nl
                        },
                        {
                            key: 'ger', country: 'Germany', city: 'Dusseldorf', flag: flag_de
                        }
                        ].map(({ key, country, city, flag }) => (
                            <div
                                data-key={key}
                                key={key}
                                onClick={() => updateOS(key)}
                                className="col-6 col-md-4 mb-3"
                            >
                                <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-white h-100">
                                    <img src={flag} alt={`${country} Flag`} className="img-fluid mb-3" />
                                    <p className="fw-bold">{country}</p>
                                    <p className="fw-light">{city}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="container py-5">
                <h3 className="text-center h3 mt-5">Deploy Anywhere, Anytime</h3>
                <p className="text-center">
                    With our intuitive interface, you can easily deploy high-performance servers in any of our global locations.
                </p>
                <div className="row justify-content-center mt-4">
                    <div className="col-md-8">
                        <Tabs defaultActiveKey="Desktop" id="deployServerTabs" className="mb-3" justify>
                            <Tab eventKey="Desktop" title="Desktop">
                                <img
                                    alt="Deploy via Desktop"
                                    src={deployDesktopimage}
                                    className="img-fluid mx-auto d-block"
                                />
                            </Tab>
                            <Tab eventKey="Tablet" title="Tablet">
                                <img
                                    alt="Deploy via Tablet"
                                    src={deployTabletimage}
                                    className="img-fluid mx-auto d-block"
                                />
                            </Tab>
                            <Tab eventKey="Mobile" title="Mobile">
                                <img
                                    alt="Deploy via Mobile"
                                    src={deployMobileImage}
                                    className="img-fluid mx-auto d-block"
                                />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>

            <div className="cta-section pageTextBreak text-white text-center py-5">
                <div className="container">
                    <h3>Ready to Get Started?</h3>
                    <p className='text-white'>Deploy your first server today and experience the power of Centnodes.</p>
                    <a target="_blank" href="https://my.centnodes.com" className="btn-centnodes btn-lg mt-3">Explore Plans</a>
                </div>
            </div>

            <Footer />
        </div>
    );
};
