import { useEffect, useState } from "react";
import logo_white from '../../images/logo_white.png'
import logo_dark from '../../images/logo_dark.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight, faMoon, faBarsStaggered } from "@fortawesome/free-solid-svg-icons";


export const SiteNavigation = () => {
    const [sideBar, setSidebar] = useState(false);

    const overlay = document.createElement("div");
    const scrollWidth = window.innerWidth - document.body.clientWidth;
    overlay.id = "overlay";
    overlay.classList.add("overlay");

    const darkModeStatus = true;
    const darkModeBtn = document.querySelector("#dark-mode");


    function cookies(cookies) {
        if (!cookies.active) return false;

        const cookiesInfo = document.createElement("div");
        cookiesInfo.setAttribute("class", "cookies-info container-fluid");

        cookiesInfo.innerHTML = `
        <div class="cookies-info-container row w-100 border">
    
            <div class="col-md-10 d-flex align-items-center justify-content-center p-2">
                <i class="bi bi-exclamation-triangle-fill md d-flex align-items-center justify-content-center me-4"></i>
                <p class="m-0">${cookies.message}</p>
            </div>
    
            <div class="col-md-2 d-flex align-items-center justify-content-center">
                <div class="btn-group d-flex flex-wrap">
                    <button id="cookie-accept" class="btn btn-primary m-1 rounded">Accept</button>
                    <button id="cookie-reject" class="btn btn-outline-primary m-1 rounded">Reject</button>
                </div>
            </div>
    
        </div>
        `;

        cookiesInfo.querySelector("#cookie-accept").addEventListener("click", e => { document.cookie = "cookies=accepted"; cookiesInfo.remove(); });
        cookiesInfo.querySelector("#cookie-reject").addEventListener("click", e => { document.cookie = "cookies=rejected"; cookiesInfo.remove(); });

        document.body.prepend(cookiesInfo);

        setTimeout(() => {
            cookiesInfo.classList.add("active");
        }, 1600);

    };

    function darkMode(isEnabled) {

        const logosDark = document.querySelectorAll(".logo-dark");
        const logosLight = document.querySelectorAll(".logo-light");
        const darkModeTxt = (darkModeBtn) ? darkModeBtn.querySelector("span") : false;

        if (isEnabled == true) {
            document.body.setAttribute("data-cc-theme", "dark");
            sessionStorage.setItem('darkMode', true)
            if (darkModeTxt) darkModeTxt.innerHTML = 'Light Mode';
            if (logosDark.length >= 1 && logosLight.length >= 1) {
                logosLight.forEach(logo => logo.classList.remove("d-none"));
                logosDark.forEach(logo => logo.classList.add("d-none"));
            };

        } else {
            document.body.removeAttribute("data-cc-theme");
            sessionStorage.removeItem('darkMode')
            if (darkModeTxt) darkModeTxt.innerHTML = 'Light Mode';
            if (logosDark.length >= 1 && logosLight.length >= 1) {
                logosLight.forEach(logo => logo.classList.add("d-none"));
                logosDark.forEach(logo => logo.classList.remove("d-none"));
            };
        }

    };



    function overflow(bool) {

        if (bool) {
            document.body.prepend(overlay);
            document.body.classList.add("overflow-hidden");
            document.body.style.paddingRight = `${scrollWidth}px`;
            if (document.getElementById('siteNavigation')) document.getElementById('siteNavigation').style.paddingRight = `${scrollWidth}px`;
        } else {
            if (overlay) overlay.remove();
            document.body.classList.remove("overflow-hidden");
            document.body.removeAttribute("style");
            if (document.getElementById('siteNavigation')) document.getElementById('siteNavigation').style.paddingRight = "unset";
        };
    };


    function toggleMenu() {
        overflow(!document.body.classList.contains("overflow-hidden"));

        if (sideBar === true) {
            document.getElementById('navbarNavDropdown').classList.remove('show')
            document.getElementById('headerOpenTop').classList.remove('d-flex')
            document.getElementById('overlay').remove()

            setSidebar(false)
        } else {
            document.getElementById('navbarNavDropdown').classList.add('show')
            document.getElementById('headerOpenTop').classList.add('d-flex')
            setSidebar(true)

        }
    }
    const darkModeToggler = () => {
        if (sessionStorage.getItem('darkMode')) {
            darkMode(false)

        } else {
            darkMode(true)
        }
        return
    }

    const darkModeCheck = () => {
        if (sessionStorage.getItem('darkMode')) {
            darkMode(true)

        } else {
            darkMode(false)
        }
        return
    }

    useEffect(() => {

        darkModeCheck()
        if (!document.cookie.includes("cookies=accepted") && !document.cookie.includes("cookies=rejected")) cookies({
            active: true,
            message: 'The website uses cookies to help personalize your internet experience. By accessing the centnodes.com, you agree to the required <a href="/privacy" class="link">Privacy Policy</a>. We may use cookies to collect, store or track information for statistical and marketing purposes on our website.</p>',
        });
        if (document.getElementById("siteNavigation")) {
            window.addEventListener('scroll', function () {

                if (window.scrollY > 100) {
                    document.getElementById('siteNavigation').classList.add('fixed-top');
                    if (document.getElementById('siteNavigation').parentElement) {
                        const fixedClass = "fixed-top"
                        if (fixedClass) {
                            document.getElementById('siteNavigation').classList.add(fixedClass);
                        };
                    };

                    let navbar_height = document.getElementById('siteNavigation').offsetHeight;
                    document.body.style.paddingTop = navbar_height + 'px';
                    setTimeout(() => {
                        document.getElementById('siteNavigation').style.top = 0;
                    }, 1000);
                } else {
                    document.getElementById('siteNavigation').classList.remove('fixed-top');
                    document.getElementById('siteNavigation').removeAttribute('style');
                    if (document.getElementById('siteNavigation').parentElement) {
                        const fixedClass = document.getElementById('siteNavigation').parentElement.dataset.fixedClass;
                        if (fixedClass) {
                            if (document.getElementById('siteNavigation').classList.contains(fixedClass)) {
                                document.getElementById('siteNavigation').classList.remove(fixedClass);
                            };
                        }
                    };
                    // remove padding top from body
                    document.body.style.paddingTop = '0';

                }
            });
        }


    }, [])

    return (
        <div>
            <button id="dark-mode" onClick={darkModeToggler} className="btn"><FontAwesomeIcon icon={faMoon} /><span className="ms-2">Dark Mode</span></button>
            <div id="siteNavigation" className="header-two fixed-header mainNav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2 col-sm-9">
                            <div className="h-100 d-flex align-items-center">
                                <a href="/" className="navbar-brand w-100 logo logo-dark"><img src={logo_dark} alt="company logo dark" width="120" height="150" /></a>
                                <a href="/" className="navbar-brand w-100 logo logo-light d-none"><img src={logo_white} alt="company logo light" width="120" height="150" /></a>
                            </div>
                        </div>
                        <div className="col-md-10 col-sm-3">
                            <div className="h-100 d-flex align-items-center justify-content-end">
                                <nav className="navbar navbar-expand-xl p-0 ms-auto">
                                    <button onClick={toggleMenu} className="navbar-toggler text- collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                        <FontAwesomeIcon color={'#444'} className="menu-button" icon={faBarsStaggered} />
                                    </button>
                                    <div className="collapse collapse-horizontal navbar-collapse" id="navbarNavDropdown">
                                        <div className="navbar-collapse-header row" id="headerOpenTop">
                                            <div className="col-md-7"><a href="/" className="navbar-brand w-100 d-flex logo"><img src={logo_white} alt="company logo light" width="120" height="150" /></a></div>
                                            <div className="col-md-5 d-flex justify-content-end"><button onClick={toggleMenu} className="navbar-toggler  navbar-toggler-sub collapsed show" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"><FontAwesomeIcon icon={faArrowAltCircleRight} /></button></div>
                                        </div>
                                        <ul className="navbar-nav">
                                            <li className="nav-item">
                                                <a className="nav-link" aria-current="page" href="/">
                                                    <i className="bi bi-house-door"></i>
                                                    <span>Home</span>
                                                </a>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" href="#" id="nav-hosting" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="bi bi-hdd-rack"></i>
                                                    <span>Services</span>
                                                </a>
                                                <ul className="dropdown-column dropdown-menu dropdown-modern" aria-labelledby="nav-hosting">
                                                    <li>
                                                        <span>Cloud Hosting</span>
                                                        <ul className="mt-2">
                                                            <li className="nav-link"><a href="/services/servers"><i className="bi bi-window-dock"></i><span className="ms-2">Windows VPS</span></a></li>
                                                            <li className="nav-link"><a href="/services/servers"><i className="bi bi-window-dock"></i><span className="ms-2">Linux VPS</span></a></li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <span>Web Hosting</span>
                                                        <ul className="mt-2">
                                                            <li className="nav-link"><a href="/services/shared-hosting"><i className="bi bi-window-dock"></i><span className="ms-2">Shared Hosting</span></a></li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <span>Game Hosting</span>
                                                        <ul className="mt-2">
                                                            <li className="nav-link"><a href="/services/gaming/minecraft"><i className="bi bi-layers"></i><span className="ms-2">Minecraft</span></a></li>
                                                            <li className="nav-link"><a href="/services/gaming/sa-mp"><i className="bi bi-layers"></i><span className="ms-2">SA-MP</span></a></li>
                                                        </ul>
                                                    </li>

                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" href="#" id="nav-hosting" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="bi bi-globe"></i>
                                                    <span>Features</span>
                                                </a>
                                                <ul className="dropdown-column dropdown-menu dropdown-modern" aria-labelledby="nav-hosting">
                                                    <li>
                                                        <span>Features</span>
                                                        <ul className="mt-2">
                                                            <li className="nav-link"><a href="/features/locations"><i className="bi bi-globe"></i><span className="ms-2">Locations</span></a></li>
                                                            <li className="nav-link"><a href="/features/oslist"><i className="bi bi-arrow-repeat"></i><span className="ms-2">Operating Systems</span></a></li>
                                                            <li className="nav-link"><a href="/features/controlpanel"><i className="bi bi-info-circle"></i><span className="ms-2">Control Panel</span></a></li>
                                                            {/* <li className="nav-link"><a href="/features/ddos"><i className="bi bi-info-circle"></i><span className="ms-2">DDoS Protection</span></a></li> */}

                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" href="#" id="nav-resources" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="bi bi-globe"></i>
                                                    <span>Resources</span>
                                                </a>
                                                <ul className="dropdown-column dropdown-menu dropdown-modern" aria-labelledby="nav-resources">
                                                    <li>
                                                        <span>Resources</span>
                                                        <ul className="mt-2">
                                                            {/* <li className="nav-link"><a href="/help"><i className="bi bi-people"></i><span className="ms-2">About Us</span></a></li> */}
                                                            <li className="nav-link"><a href="/blog"><i className="bi bi-rss"></i><span className="ms-2">Blog</span></a></li>
                                                            <li className="nav-link"><a href="/knowledgebase"><i className="bi bi-card-text"></i><span className="ms-2">Knowledgebase</span></a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li className="nav-item">
                                                <a className="nav-link" aria-current="page" href="/help">
                                                    <i className="bi bi-house-door"></i>
                                                    <span>Support</span>
                                                </a>
                                            </li>
                                            <li className="ms-md-1 mt-3 mt-lg-0 nav-item nav-item-btn">
                                                <a className="btn btn-primary text-white ms-4" aria-current="page" href="https://my.centnodes.com/register">
                                                    <span>Get Started</span>
                                                </a>
                                            </li>
                                            <li className="ms-md-1 mt-2 mt-lg-0 nav-item nav-item-btn ">
                                                <a className="btn btn-outline-primary rounded ms-4" aria-current="page" href="https://my.centnodes.com/login">
                                                    <span>Sign In</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}