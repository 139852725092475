import React, { useEffect } from 'react'
import { SiteNavigation } from '../../components/Navigation/SiteNavigation'
import './Legal.css'
import Footer from "../../components/Footer/Footer";
import contactImage from '../../images/design/server-12.svg'
import { Helmet } from 'react-helmet-async';

export const GDPR = () => {

    useEffect(() => {

    }, [])
    return (
        <>
            <Helmet>
                <title>Centnodes | GDPR</title>
                <meta
                    name="description"
                    content="GDPR is a European Union privacy law that sets strict standards for the protection of personal data and grants individuals control over their own data."
                />;
            </Helmet>
            <SiteNavigation />
            <div className="border-bottom py-4">
                <div className="container ">
                    <div className="row align-items-center mt-5 ">
                        <div className="col-md-5 d-flex flex-column">
                            <span className="h5">GDPR</span>
                            <p className="mt-2">GDPR is a European Union privacy law that sets strict standards for the protection of personal data and grants individuals control over their own data.
                            </p>
                        </div>
                        <div className="col-md-7 d-flex justify-content-center align-items-center mt-5 mt-xl-0">
                            <div className="content-img"><img src={contactImage} alt="server" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-wrapper">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className='tos'>
                                        <p><strong>Transparency and Fairness:</strong> Centnodes is committed to processing your personal data transparently and fairly. We will inform you about how your data is used and ensure that the processing is lawful.</p>

                                        <p><strong>Purpose Limitation:</strong> We collect and process your data only for specific, explicit, and legitimate purposes, ensuring it is not used for any other incompatible purposes.</p>

                                        <p><strong>Data Minimization:</strong> We collect and store only the data that is necessary for the services we provide, avoiding excessive or irrelevant information.</p>

                                        <p><strong>Data Accuracy:</strong> Centnodes will take measures to maintain the accuracy of your data and keep it up to date.</p>

                                        <p><strong>Data Storage:</strong> We retain your personal data only for as long as required for the purposes for which it was collected, and we will securely delete it when no longer needed.</p>

                                        <p><strong>Data Security:</strong> Centnodes is committed to implementing robust security measures to protect your personal data from breaches and to maintain its confidentiality.</p>

                                        <p><strong>Accountability:</strong> We demonstrate compliance with GDPR and ensure the proper handling of your data.</p>

                                        <p><strong>Consent:</strong> If we rely on your consent to process your data, we will ensure that it is freely given, specific, informed, and unambiguous.</p>

                                        <p><strong>Data Subject Rights:</strong> As our valued customer, you have rights under GDPR, including the right to access your data, the right to be forgotten, the right to data portability, and others. We are here to help you exercise these rights.</p>

                                        <p><strong>Data Protection Impact Assessments (DPIAs):</strong> We conduct DPIAs for high-risk data processing activities to ensure your data's security.</p>

                                        <p><strong>Data Protection Officer (DPO):</strong> While we may not be required to designate a DPO, we take data protection seriously and have dedicated personnel to address your concerns.</p>

                                        <p><strong>International Data Transfers:</strong> If we transfer your data outside the EU, we ensure it's done in compliance with GDPR's rules and safeguards.</p>

                                        <p><strong>Breach Notification:</strong> In the rare event of a data breach, we are committed to reporting it to the appropriate authorities and notifying you as necessary.</p>

                                        <p><strong>Legal Basis for Processing:</strong> We identify lawful bases for processing your data, such as contractual necessity or legitimate interests in providing you with the best possible services.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    )
}
