import { faArrowUp, faCamera, faCancel, faCheckCircle, faClock, faBoltLightning, faGear, faDesktopAlt, faUpload, faHeadset, faFlag } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Footer from '../../components/Footer/Footer'
import { SiteNavigation } from '../../components/Navigation/SiteNavigation'
import './ServerServices.css'
import serverPlanImage from '../../images/design/freedom_home.svg'
import serverHeaderImage from '../../images/design/server_services.svg'
import imageDelivery from '../../images/design/instant_delivery_image.svg'
import { Helmet } from 'react-helmet-async'

export const ServerServices = () => {

    return (
        <div>
            <Helmet>
                <title>Centnodes | Virtual Private Servers</title>
                <meta
                    name="description"
                    content="Get high performance without the high costs. There is a Centnodes Server available for every need – and now, with 3x more traffic included by default, there's never been a better time to buy."
                />;
            </Helmet>
            <SiteNavigation />
            <div className="border-bottom py-4">
                <div className="container ">
                    <div className="row align-items-center mt-5 ">
                        <div className="col-md-5 d-flex flex-column">
                            <span className="h5">Cloud Hosting</span>
                            <span className="h2 mt-2">Top notch servers to boost your business.</span>
                            <p className="mt-2">Get high performance without the high costs. There is a Centnodes Server available for every need – and now, with 3x more traffic included by default, there's never been a better time to buy.</p>
                            <div className="btn-group">
                                <a href="#packages" className="btn btn-primary rounded m-1">See packages</a>
                                <a href="/help" className="btn btn-outline-primary rounded m-1">Contact Us</a>
                            </div>
                        </div>
                        <div className="col-md-7 d-flex justify-content-center align-items-center mt-5 mt-xl-0">
                            <div className="content-img"><img alt='server image' src={serverHeaderImage} /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-bottom bg-white py-4">
                <div className="container  mt-5">
                    <div className="row mt-5 text-center">
                        <h1 className="h2">Choose the VPS that suits you, from Starter to Expert</h1>
                        <div className="col-md-3 col-lg-3 col-6">
                            <div className="hostingPackage p-4 bg-white">
                                <div className="hostingPackageTitle p-0 border">
                                    <h5>Cloud VPS I</h5>
                                </div>
                                <h6 className="border-top pt-2">Specifications</h6>
                                <div className="planSpecifications mt-3">
                                    <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">1 Core</span> CPU</p>
                                    <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">2 GB</span> RAM</p>
                                    <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">20 GB</span> SSD Storage</p>
                                </div>
                                <h5 className="text-center">&euro;4,99 /mo</h5>
                                <div className="mt-4 d-flex justify-content-center">
                                    <a href={'https://my.centnodes.com/login'} className="btn btn-centnodes">Order {'>'}</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 col-lg-3 col-6">
                            <div className="hostingPackage p-4 bg-white">
                                <div className="hostingPackageTitle p-0 border">
                                    <h5>Cloud VPS II</h5>
                                </div>
                                <h6 className="border-top pt-2">Specifications</h6>
                                <div className="planSpecifications mt-3">
                                    <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">2 Core</span> CPU</p>
                                    <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">4 GB</span> RAM</p>
                                    <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">40 GB</span> SSD Storage</p>
                                </div>
                                <h5 className="text-center">&euro;9,99 /mo</h5>
                                <div className="mt-4 d-flex justify-content-center">
                                    <a href={'https://my.centnodes.com/login'} className="btn btn-centnodes">Order {'>'}</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 col-lg-3 col-6">
                            <div className="hostingPackage p-4 bg-white">
                                <div className="hostingPackageTitle p-0 border">
                                    <h5>Cloud VPS III</h5>
                                </div>
                                <h6 className="border-top pt-2">Specifications</h6>
                                <div className="planSpecifications mt-3">
                                    <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">4 Core</span> CPU</p>
                                    <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">12 GB</span> RAM</p>
                                    <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">80 GB</span> SSD Storage</p>
                                </div>
                                <h5 className="text-center">&euro;19,99 /mo</h5>
                                <div className="mt-4 d-flex justify-content-center">
                                    <a href={'https://my.centnodes.com/login'} className="btn btn-centnodes">Order {'>'}</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 col-lg-3 col-6">
                            <div className="hostingPackage p-4 bg-white">
                                <div className="hostingPackageTitle p-0 border">
                                    <h5>Cloud VPS IV</h5>
                                </div>
                                <h6 className="border-top pt-2">Specifications</h6>
                                <div className="planSpecifications mt-3">
                                    <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">6 Core</span> CPU</p>
                                    <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">24 GB</span> RAM</p>
                                    <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className="fw-bold">100 GB</span> SSD Storage</p>
                                </div>
                                <h5 className="text-center">&euro;49,99 /mo</h5>
                                <div className="mt-4 d-flex justify-content-center">
                                    <a href={'https://my.centnodes.com/login'} className="btn btn-centnodes">Order {'>'}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-light border-top border-bottom pt-5 pb-5 mt-3">
                    <div class="container text-center pt-2 pb-2">

                        <h4 class="h2 lead-title">See more about the technical specifications of our plans</h4>

                        <p class="lead-text">Our VPS Server plans include a wide range of additional features</p>

                        <div class="row g-4 text-start justify-content-center align-items-stretch">
                            <div class="col-md-6">
                                <div class="card shadow-sm rounded">
                                    <div class="card-header hostingPackageTitle pageTextBreak border text-white rounded-top p-3">
                                        <h5 class="mb-0 d-flex align-items-center">
                                            <i class="bi bi-hdd-network me-2"></i>
                                            Our Standard VPS Server Specifications
                                        </h5>
                                    </div>
                                    <div class="card-body p-4">
                                        <ul class="list-unstyled mb-0">
                                            <li class="d-flex align-items-center mb-2">
                                                <FontAwesomeIcon icon={faCheckCircle} className="me-2 text-success" />
                                                Instant Provisioning
                                            </li>
                                            <li class="d-flex align-items-center mb-2">
                                                <FontAwesomeIcon icon={faCheckCircle} className="me-2 text-success" />
                                                Free Entry Bandwidth
                                            </li>
                                            <li class="d-flex align-items-center mb-2">
                                                <FontAwesomeIcon icon={faCheckCircle} className="me-2 text-success" />
                                                Includes SSD and RAID protection
                                            </li>
                                            <li class="d-flex align-items-center mb-2">
                                                <FontAwesomeIcon icon={faCheckCircle} className="me-2 text-success" />
                                                Scale up on demand
                                            </li>
                                            <li class="d-flex align-items-center mb-2">
                                                <FontAwesomeIcon icon={faCheckCircle} className="me-2 text-success" />
                                                Includes Basic DDoS protection
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="card shadow-sm rounded">
                                    <div class="card-header hostingPackageTitle pageTextBreak border text-white rounded-top p-3">
                                        <h5 class="mb-0 d-flex align-items-center">
                                            <i class="bi bi-hdd-rack me-2"></i>
                                            Other VPS Server Specifications Included
                                        </h5>
                                    </div>
                                    <div class="card-body p-4">
                                        <ul class="list-unstyled mb-0">
                                            <li class="d-flex align-items-center mb-2">
                                                <FontAwesomeIcon icon={faCheckCircle} className="me-2 text-success" />
                                                Full access to root server
                                            </li>
                                            <li class="d-flex align-items-center mb-2">
                                                <FontAwesomeIcon icon={faCheckCircle} className="me-2 text-success" />
                                                Super Control Panels
                                            </li>
                                            <li class="d-flex align-items-center mb-2">
                                                <FontAwesomeIcon icon={faCheckCircle} className="me-2 text-success" />
                                                Easy migration / scalability
                                            </li>
                                            <li class="d-flex align-items-center mb-2">
                                                <FontAwesomeIcon icon={faCheckCircle} className="me-2 text-success" />
                                                Full Management Available
                                            </li>
                                            <li class="d-flex align-items-center mb-2">
                                                <FontAwesomeIcon icon={faCheckCircle} className="me-2 text-success" />
                                                Choice of operating systems
                                            </li>
                                            <li class="d-flex align-items-center mb-2">
                                                <FontAwesomeIcon icon={faCheckCircle} className="me-2 text-success" />
                                                Firewall and advanced networking made easy
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='container py-4'>
                    <div className="row justify-content-between mt-5 mb-5">
                        <div className="col-xl-5 col-lg-5 col-md-12 flex-column justify-content-start">
                            <span className="h2">Instant Activation</span>
                            <p className="mt-2">Once the payment for your service has been identified by the chosen payment method. Our control panel will activate your service right away.</p>
                            <div className="row mt-2">
                                <div className="col-md-6 d-flex flex-column rounded mt-3">
                                    <div className=" icon-rounded-box  bg-yellow-light mb-3">
                                        <FontAwesomeIcon icon={faClock} className="text-yellow" />
                                    </div>
                                    <span className="h5">Instant Activation</span>
                                    <span>Service is active instantly after payment confirmation.</span>
                                </div>
                                <div className="col-md-6 d-flex flex-column rounded mt-3">
                                    <div className=" icon-rounded-box  bg-purple-light mb-3">
                                        <FontAwesomeIcon icon={faCancel} className="text-purple" />
                                    </div>
                                    <span className="h5">Easy Cancellation</span>
                                    <span>Cancel your service instantly whenever you want.</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5 col-md-12 flex-column justify-content-end align-items-end">
                            <div className="bg-red-light rounded p-5">
                                <img src={imageDelivery} alt="clock" />
                            </div>

                        </div>

                    </div>
                </div>
                <section className="bg-white border-top">
                    <div className="container">
                        <h4 className="h2 lead-title text-center">Why choose Centnodes Cloud VPS?</h4>
                        <p className="lead-text text-center">Below we list some advantages of choosing Centnodes for your Cloud VPS hosting.</p>
                        <div className="row gy-4 box text-start justify-content-center align-items-center">
                            <div className="col-lg-4">
                                <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                                    <FontAwesomeIcon icon={faBoltLightning} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                    <span className="h5 mt-2">Performance</span>
                                    <span>High-performance VPS hosting with dedicated resources for reliable speed and efficiency.</span>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                                    <FontAwesomeIcon icon={faGear} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                    <span className="h5 mt-2">Scalability</span>
                                    <span>Scale your resources up or down effortlessly to match your business's evolving needs.</span>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                                    <FontAwesomeIcon icon={faDesktopAlt} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                    <span className="h5 mt-2">Control</span>
                                    <span>Enjoy full control of your VPS with our intuitive and user-friendly control panel.</span>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                                    <FontAwesomeIcon icon={faUpload} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                    <span className="h5 mt-2">Reliability</span>
                                    <span>Experience consistent uptime and availability for all your applications.</span>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                                    <FontAwesomeIcon icon={faHeadset} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                    <span className="h5 mt-2">Support</span>
                                    <span>Get professional, 24/7 customer support to resolve any issues you encounter.</span>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                                    <FontAwesomeIcon icon={faFlag} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                    <span className="h5 mt-2">Freedom</span>
                                    <span>Install and configure any software or application that fits your specific requirements.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section aria-label="Common questions" className="pt-5 border-bottom">
                    <div className="container">
                        <div className="row g-4">
                            <div className="col-md-6">
                                <div className="text-left me-5">
                                    <span className="h2">Common questions</span>
                                    <p className="mt-4">To help you make an informed decision, we've compiled a list of our frequently asked questions related to our Cloud VPS services.</p>
                                    <p>If you cannot find the answer to your question or would like more information, you can visit our Knowledgebase or contact us anytime, and we will be happy to assist you.</p>
                                    <div className="btn-groups">
                                        <a href="/knowledgebase" className="btn btn-primary mt-1 mb-1">Knowledgebase</a>
                                        <a href="/help" className="btn btn-outline-transparent mt-1 mb-1">Contact Us</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="text-left">
                                    <span className="h3">Do you have any doubts?</span>
                                    <p className="mt-3">See if your question has already been answered in the list below.</p>
                                    <div className="accordion accordion-flush" id="accordionQuestion">
                                        <div className="accordion-item border mt-1 mb-1">
                                            <h2 className="accordion-header" id="flush-headingOne">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">What makes your Cloud VPS different?</button>
                                            </h2>
                                            <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionQuestion">
                                                <div className="accordion-body">Our Cloud VPS is designed to offer dedicated resources, ensuring top performance and reliability.</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item border mt-1 mb-1">
                                            <h2 className="accordion-header" id="flush-headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">Can I scale my VPS resources?</button>
                                            </h2>
                                            <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionQuestion">
                                                <div className="accordion-body">Yes, our VPS solutions are fully scalable to grow alongside your business needs.</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item border mt-1 mb-1">
                                            <h2 className="accordion-header" id="flush-headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">What kind of uptime can I expect?</button>
                                            </h2>
                                            <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionQuestion">
                                                <div className="accordion-body">Our Cloud VPS guarantees excellent uptime, ensuring your services are available 24/7.</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item border mt-1 mb-1">
                                            <h2 className="accordion-header" id="flush-headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">Can I customize my VPS setup?</button>
                                            </h2>
                                            <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionQuestion">
                                                <div className="accordion-body">Absolutely, you can install and configure any software to meet your unique requirements.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className='container'>
                    <div className="row my-5 m-0 pageTextBreak">
                        <div className="col-12 col-md-8 col-lg-8">
                            <div className='pageTextBreakBox'>
                                <h3 className="">Our customer service is happy to help</h3>
                                <h6>For example, look for frequently asked questions on our extensive FAQ page. On topics such as hosting, e-mail, domain names or WordPress. These pages are maintained by our expert customer service team and are therefore very up-to-date. Is your question not there? Our customer service is ready for you! Contact us via chat or email. </h6>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4 d-none d-md-block d-lg-block">
                            <img alt='server plan image' src={serverPlanImage} className="img-fluid imageTextBreak mt-4" />
                        </div>
                    </div>
                </div >
            </div>


            <div className="testimonials-section bg-light py-5">
                <div className="container">
                    <h2 className="text-center">What Our Customers Are Saying</h2>
                    <div className="row mt-4">
                        <div className="col-md-4">
                            <blockquote className="blockquote">
                                <p>"Centnodes' control panel is a game-changer. Managing my VPSs has never been easier."</p>
                                <footer className="blockquote-footer">Castoro, Developer</footer>
                            </blockquote>
                        </div>
                        <div className="col-md-4">
                            <blockquote className="blockquote">
                                <p>"I love how fast I can deploy servers. It saves me so much time!"</p>
                                <footer className="blockquote-footer">Dante, Minecraft server owner</footer>
                            </blockquote>
                        </div>
                        <div className="col-md-4">
                            <blockquote className="blockquote">
                                <p>"The performance insights are invaluable. I can monitor and optimize my servers with ease."</p>
                                <footer className="blockquote-footer">Tony, Website owner</footer>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta-section pageTextBreak text-white text-center py-5">
                <div className="container">
                    <h3>Ready to Get Started?</h3>
                    <p className="text-white">Deploy your first server today and experience the power of Centnodes.</p>
                    <a target="_blank" href="https://my.centnodes.com" className="btn-centnodes btn-lg mt-3">Explore Plans</a>
                </div>
            </div>

            <Footer />
            <div className="botomButtons d-none" id={'backToTopButton'}>
                <div className="row p-0 m-0">
                    <div className="col-2 backToTop justify-content-start">
                        <FontAwesomeIcon className="backToTopIcon" onClick={() => window.scrollTo(0, 0)} icon={faArrowUp} />
                    </div>
                </div>
            </div>
        </div >
    )
}



