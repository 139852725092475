import React, { useEffect, useState } from 'react'
import { SiteNavigation } from '../../components/Navigation/SiteNavigation'
import './KnowledgeBaseItem.css'
import Footer from '../../components/Footer/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowUp, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import parse from 'html-react-parser'
import apiService from '../../services/apiService'

const KnowledgeBaseItem = () => {
    const [knowledgeItem, setKnowledgeItem] = useState()
    const [categories, setCategories] = useState();

    const id = window.location.href.split("/knowledgebase/read").pop().replace('/', '');


    const getItem = async (id) => {
        console.log(id);
        let data = await apiService.getKnowledgebaseItem(id);
        setKnowledgeItem(data)
        // getCategories
    }

    const getCategories = async () => {
        let cats = await apiService.getKnowledgeCategories()

        setCategories(cats)
    }

    const goToCategory = (name) => {
        window.location.href = '/knowledgebase/category/' + name;
    }

    useEffect(() => {
        getItem(id)
    }, [id])

    return (
        <div>
            <SiteNavigation />
            <div className="border-bottom py-4 bg-test">
                <div className="container ">
                    <div className="row align-items-center mt-5 ">
                        <div className="col-md-5 d-flex flex-column">
                            <span className="h5">{knowledgeItem && knowledgeItem.title}</span>
                            <span><a href="#" onClick={() => window.location.href = '/knowledgebase'} className="d-inline text-white">Knowledgebase </a><FontAwesomeIcon icon={faArrowRight} /> <a href="#" onClick={() => window.location.href = `/knowledgebase/${knowledgeItem ? knowledgeItem.category : 'general'}`} className="d-inline text-white">{knowledgeItem && knowledgeItem.category} </a> <FontAwesomeIcon icon={faArrowRight} /> {knowledgeItem && knowledgeItem.title}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row mt-4 pb-2">
                    <div className="d-none d-md-block d-lg-block col-md-3">
                        <div className="categoryBoxKnowledgeBase p-3 card">
                            <h6 className="mt-2">Related articles</h6>
                            <ul className="list-unstyled categoryList">
                                {/* {categories && categories.map(category => (
                                    <li onClick={() => goToCategory(category.name)} className='list-item'><FontAwesomeIcon color={'#2f57a4'} icon={faArrowRight} /> <span>{category.name}</span></li>
                                ))} */}

                            </ul>
                        </div>
                        <div className='categoryBoxKnowledgeBase p-3 card mt-3'>
                            <h6 className="mt-2">Was this helpful?</h6>
                            <div className='row mt-2 d-flex justify-content-center text-center'>
                                <div className='col-6'>
                                    <FontAwesomeIcon className='reviewIcon' color={'#2f57a4'} size={'2xl'} icon={faThumbsUp} />
                                </div>
                                <div className='col-6'>
                                    <FontAwesomeIcon className='reviewIcon' color={'#2f57a4'} size={'2xl'} icon={faThumbsDown} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <img src={knowledgeItem && knowledgeItem.image} className='img-fluid w-100' />
                        <div className="p-3 card">
                            <h3 className="mt-2 color-centnodes"> {knowledgeItem && knowledgeItem.title}</h3>
                            <div className="knowledgeBaseContent text-dark">
                                {knowledgeItem && parse(knowledgeItem.content)}
                            </div>
                        </div>
                    </div>
                    <div className="d-block d-md-none d-lg-none col-12">
                        <div className="categoryBoxKnowledgeBase p-3 card">
                            <h6 className="mt-2">Categories</h6>
                            <ul className="list-unstyled categoryList">
                                {categories && categories.map(category => (
                                    <li onClick={() => goToCategory(category.name)} className='list-item'><FontAwesomeIcon color={'#2f57a4'} icon={faArrowRight} /> <span>{category.name}</span></li>
                                ))}

                            </ul>
                        </div>
                        <div className='categoryBoxKnowledgeBase p-3 card mt-3'>
                            <h6 className="mt-2 color-centnodes">Was this helpful?</h6>
                            <div className='row mt-2 d-flex justify-content-center text-center'>
                                <div className='col-6'>
                                    <FontAwesomeIcon className='reviewIcon' color={'#2f57a4'} size={'2xl'} icon={faThumbsUp} />
                                </div>
                                <div className='col-6'>
                                    <FontAwesomeIcon className='reviewIcon' color={'#2f57a4'} size={'2xl'} icon={faThumbsDown} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
            <div className="botomButtons d-none" id={'backToTopButton'}>
                <div className="row p-0 m-0">
                    <div className="col-2 backToTop justify-content-start">
                        <FontAwesomeIcon className="backToTopIcon" onClick={() => window.scrollTo(0, 0)} icon={faArrowUp} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default KnowledgeBaseItem;
